<template>
  <div>
    <div class="rows">
      <div class="col-md-12">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">{{$t('Zones Price')}}</h3>
            </div>
            <span class="add"> </span>
          </div>
          <div class="card-body">
            <div class="col-xl-12 mt-4">
              <template>
                <GmapMap
                  style="width: 100%; height: 50vh"
                  :center="{
                    lat: currentLocation.latitude,
                    lng: currentLocation.longitude,
                  }"
                  :zoom="10"
                >
                  <GmapMarker
                    label="★"
                    :position="{
                      lat: currentLocation.latitude,
                      lng: currentLocation.longitude,
                    }"
                  />
                  <!--<GmapCircle
                    ref="circleRef"
                    :center="{
                      lat: currentLocation.latitude,
                      lng: currentLocation.longitude,
                    }"
                    :radius="distance.base * 1000"
                    :visible="true"
                    :options="{
                      fillColor: 'blue',
                      fillOpacity: 0.0,
                      strokeWeight: 1,
                      clickable: true,
                    }"
                  />-->

                  <div v-for="t in zone_price" :key="t.id">
                    <GmapCircle
                      ref="circleRef"
                      :center="{
                        lat: currentLocation.latitude,
                        lng: currentLocation.longitude,
                      }"
                      :radius="t.distance * 1000"
                      :visible="true"
                      :options="{
                        fillColor: 'red',
                        fillOpacity: 0.0,
                        strokeWeight: 1,
                        clickable: true,
                        strokeOpacity: 0.4,
                      }"
                    />
                  </div>
                </GmapMap>
              </template>
            </div>
            <div class="row">
              <!--<div class="col-xl-12 mt-4">
                <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                  <b-input-group-prepend>
                    <b-input-group-text style="width: 75px">
                      Zone 1
                    </b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input :id="'1'" value="1.50" disabled></b-form-input>
                  <b-input-group-append>
                    <b-input-group-text> JOD </b-input-group-text>
                  </b-input-group-append>
                  <b-input-group-append>
                    <b-input-group-text style="width: 70px">
                      3.25 KM
                    </b-input-group-text>
                  </b-input-group-append>
                </b-input-group>
              </div>-->
              <div class="col-xl-12 mt-4" v-for="zone in zone_price" :key="zone.id">
                <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                  <b-input-group-prepend>
                    <b-input-group-text style="width: 75px">
                      {{zone.name}}
                    </b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input
                    :id="zone.id.toString()"
                    v-model="zone.price"
                    disabled
                  ></b-form-input>
                  <b-input-group-append>
                    <b-input-group-text> {{$t('JOD')}} </b-input-group-text>
                  </b-input-group-append>
                  <b-input-group-append>
                    <b-input-group-text style="width: 70px">
                      {{ zone.distance }} {{$t('KM')}}
                    </b-input-group-text>
                  </b-input-group-append>
                </b-input-group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
 

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
//import { mapState } from "vuex";

export default {
  name: "ZonesPrice",

  data() {
    return {
      markers: [],

      currentLocation: { latitude: 0, longitude: 0 },
      distance: {
        base: null,
        additional: null,
      },
      price: {
        base: null,
        additional: null,
      },
      temp: [],
      user: "",
      total: "",
      totalPrice: "",
      tempBasePrice: "",
      zone_price:'',
      data:''
    };
  },
  created(){
    this.data = JSON.parse(localStorage.getItem('client'))
    

  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Zones Price", route: "/Clients/Zones-Price" },
    ]);
    
    //console.log(this.data.data)
    if(this.data !==null){
      
    
    this.zone_price = JSON.parse(this.data.client_tarrifs.zones)
    //this.user = await this.$store.dispatch(VERIFY_AUTH);
    //console.log(this.zone_price)
    var cl = this.data
    this.currentLocation.longitude = parseFloat(cl.long);
    this.currentLocation.latitude = parseFloat(cl.lat);
}
    //-------------------------------------------------

    //Distance
    /*this.distance.base = 3.25;
    this.distance.additional = parseFloat(cl.additional_distance);
    this.total = this.distance.base;*/

    //Price
    /*this.price.base = 1.5;
    this.tempBasePrice = this.price.base.toFixed(2);
    this.price.additional = parseFloat(cl.additional_charge);
    this.totalPrice = parseFloat(cl.base_charge) - 1;*/

    /*for (var x = 1; x <= 9; x++) {
      this.total = this.total + this.distance.additional;
      this.totalPrice = this.totalPrice + this.price.additional;
      this.temp.push({
        id: x,
        distance: this.total,
        price: this.totalPrice.toFixed(2),
      });
    }*/
  },
  computed: {
    /*client() {
      return this.clients;
    },
    ...mapState({
      clients: (state) => state.clients.clients,
    }),*/
  },
  methods: {
    setPlace(place) {
      this.place = place;
    },
    usePlace() {
      if (this.place) {
        this.markers.push({
          position: {
            lat: this.currentLocation.latitude,
            lng: this.currentLocation.longitude,
          },
        });
        this.place = null;
      }
    },
  },
};
</script>

<style scoped>
.add {
  margin-top: 20px;
}
.col-md-12 {
  position: relative;
  width: 100%;
  padding-right: 0;
  padding-left: 0;
}

@media only screen and (max-width: 600px) {
  .col-md-12 {
    position: relative;
    width: 110%;
    margin-left: -20px;
    padding-right: 0;
    padding-left: 0;
  }
}
</style>